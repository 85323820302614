<template>
  <FragranceTeaser
    :product-id="fragranceTeaserProductId"
    :element-id="element.id"
    :element-name="element.name"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      fragranceTeaserProductId() {
        return this.getValue("fragrance")
      },
    },
  }
</script>
